.recap
    margin-top: 50px
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    padding: 0 5vw

.players
    cursor: pointer
    border: .2vw solid #ffffff00
    .moneyitemrecap
        display: flex
        flex-direction: row
        justify-content: space-around
        align-items: center
        margin: 0 0 1vw 0
        width: 15vw
        img
            width: 3vw
            height: 3vw
            margin: 0
    img
        width: 15vw
        display: block
        margin: 0 auto 1vw auto

    .statrecap
        width: 20vw
        display: flex
        border: .2vw solid #6697E6
        padding: .5vw .5vw
        margin-bottom: .5vw
        justify-content: space-between

    &:hover
        border: .2vw solid #000000