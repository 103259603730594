$primary: #3A3C4F
$secondary: #6697E6
$primary-hover: rgb(4, 24, 124)
$secondary-hover: rgb(42, 112, 235)


html, body
    background-color: #F6F7FC 

.btn-primary
    position: relative
    color: white
    font-family: "Poppin bold"
    font-size: .8vw
    height: 3vw
    min-width: 8vw
    padding: 0 2vw
    border: none
    border-radius: .8vw
    background: $secondary
    margin-top: 1vw
    margin-right: 2vw
    &:hover
        cursor: pointer
        background: $secondary-hover

.btn-primary-icon
    position: relative
    color: white
    font-family: "Poppin bold"
    font-size: .8vw
    height: 3vw
    min-width: 8vw
    padding: 0 2vw 0 3.5vw
    border: none
    border-radius: .8vw
    background: $secondary
    margin-top: 1vw
    margin-right: 2vw
    &:hover
        cursor: pointer
        background: $secondary-hover
    svg
        position: absolute
        width: 1.5vw
        height: 1.5vw
        left: 1vw
        top: .8vw

h1, h2, h3, h4, h5, h6
    font-family: "Poppin bold"
    color: $primary
    margin: 0
    padding: 0

h1
    font-size: 2.5vw

h2
    font-size: 2vw

h3
    font-size: 1.5vw

h4
    font-size: 1.2vw

h5
    font-size: 1vw

input[type="text"]
    font-family: "Poppin"
    background-color: #fff
    border: none
    padding: .5vw 1vw
    font-size: 1vw
    border-top-left-radius: .5vw
    border-top-right-radius: .5vw
    border-bottom-right-radius: .5vw
    border-bottom-left-radius: .5vw
