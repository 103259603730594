@import "./fonts.sass"
@import "./theme.sass"

@media (min-width: 739px)
    .myMenu
        position: relative
        min-height: 100vh
        .footer-img
            position: absolute
            bottom: 0
            right: 0
            max-height: 30vh
            width: 80vw
            object-fit: cover
            object-position: center
            pointer-events: none
            object-position: top
            z-index: 0
        .title 
            margin-top: 1vw
            display: flex
            align-items: center
            justify-content: space-around
            width: 100%
            margin: auto
            border-bottom: 1px solid #393C49
            h1
                font-family: "Poppin"
                font-size: 2vw
                color: $primary
                margin: 2vw 0 0 2vw 
            h2
                font-family: "Poppin"
                font-size: 1.2vw
                color: #333333
                opacity: 0.4
                margin: .5vw 0 1.5vw 2vw
            button
                position: relative
                color: white
                font-family: "Poppin bold"
                font-size: 1vw
                padding-left: 3vw
                height: 4vw
                width: 14vw
                border: none
                border-radius: .8vw
                background: $secondary
                margin-top: 1vw
                margin-right: 2vw
                &:hover
                    cursor: pointer
                    background: $secondary-hover
                svg
                    position: absolute
                    width: 2vw
                    height: 2vw
                    left: 1.5vw
                    top: 1vw
        .content
            position: relative
            z-index: 10
            ul
                display: flex
                flex-wrap: wrap
                justify-content: space-around
                list-style: none
                margin-left: 5vw
                margin-right: 5vw
                padding: 0
                margin-bottom: 5vw
            li
                position: relative
                width: 20vw
                border-radius: 1vw
                height: 12vw
                margin-top: 2vw
                margin-bottom: 2vw
                display: flex
                flex-direction: column
                &:hover
                    cursor: pointer
                span
                    font-family: "Poppin bold"
                    font-size: 1.5vw
                    color: white
                    margin-top: .7vw
                    margin-bottom: 1vw
                    text-shadow: 0px 4px 5px rgba(0, 0, 0, 0.9)
                    margin-left: 1vw
                div
                    display: flex
                    justify-content: space-between
                    width: 18vw
                    margin: auto
                button
                    position: relative
                    color: lightgrey
                    font-family: "Poppin bold"
                    font-size: .6vw
                    padding-left: 2vw
                    height: 2vw
                    border: none
                    background: none
                    margin-top: -.5vw
                    margin-left: 0vw
                    width: 8vw
                    &:hover
                        cursor: pointer
                        color: white
                    svg
                        position: absolute
                        width: 1.5vw
                        height: 1.5vw
                        left: 0.5vw
                        top: .1vw
            .skeleton
                width: 20vw
                height: 12vw
                border-radius: 1vw
                margin-top: 2vw
                margin-bottom: 2vw
            .mask
                position: absolute
                width: 100%
                height: 100%
                background-size: cover
                background-position: center
                overflow: hidden
                border-radius: 1vw
                z-index: -1
            .maskcolor
                position: absolute
                width: 100%
                height: 100%
                border-radius: 1vw
                opacity: 0.7
                z-index: -1
                backdrop-filter: saturate(180%) blur(15px)
                background-color: $primary

            .icon
                width: 8vw
                height: 5vw
                margin-left: 1vw
                margin-top: 1vw
                object-fit: cover
                border-radius: 1vw
            .add
                color: $secondary
                background-color: #fff
                border: none
                text-align: center
                font-family: "Poppin"
                font-size: 1.5vw
                box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)
                svg
                    margin: 0 auto
                    width: 3vw
                    height: 3vw
                    margin-top: 3.5vw
                    color: #000
@media (max-width: 740px)
    .myMenu
        .title 
            margin-left: 10vw
            margin-top: 5vw
            display: flex
            align-items: center
            h1
                font-family: "ConcertOne"
                font-size: 8vw
                color: $primary
            button
                position: relative
                color: $secondary
                font-family: "Poppin bold"
                font-size: 3vw
                padding-left: 7vw
                height: 2vw
                border: none
                background: none
                margin-top: 1vw
                margin-left: 2vw
                &:hover
                    cursor: pointer
                    color: $secondary-hover
                svg
                    position: absolute
                    width: 5vw
                    height: 5vw
                    left: 0.5vw
                    top: -.1vw
        .content
            ul
                display: flex
                flex-wrap: wrap
                justify-content: space-between
                list-style: none
                margin-left: 10vw
                margin-right: 10vw
                padding: 0
                margin-bottom: 5vw
            li
                position: relative
                width: 80vw
                border-radius: 5vw
                height: 60vw
                margin-top: 2vw
                margin-bottom: 2vw
                display: flex
                flex-direction: column
                &:hover
                    cursor: pointer
                span
                    font-family: "Poppin bold"
                    font-size: 8vw
                    color: white
                    margin-top: 1vw
                    margin-bottom: 1vw
                    text-shadow: 0px 4px 5px rgba(0, 0, 0, 0.9)
                    margin-left: 7vw
                div
                    display: flex
                button
                    position: relative
                    color: lightgrey
                    font-family: "Poppin bold"
                    font-size: 3vw
                    padding-left: 2vw
                    height: 2vw
                    border: none
                    background: none
                    margin-top: 8vw
                    margin-left: 7vw
                    width: 40vw
                    &:hover
                        cursor: pointer
                        color: white
                    svg
                        position: absolute
                        width: 8vw
                        height: 8vw
                        left: -2vw
                        top: -2vw
            .skeleton
                width: 80vw
                border-radius: 5vw
                height: 60vw
                margin-top: 2vw
                margin-bottom: 2vw
            .mask
                position: absolute
                width: 100%
                height: 100%
                background-size: cover
                background-position: center
                overflow: hidden
                border-radius: 5vw
                z-index: -1
            .maskcolor
                position: absolute
                width: 100%
                height: 100%
                border-radius: 5vw
                opacity: 0.7
                z-index: -1
                backdrop-filter: saturate(180%) blur(15px)
                background-color: $primary

            .icon
                width: 20vw
                height: 20vw
                margin-left: 8vw
                margin-top: 8vw
            .add
                color: $secondary
                border: 2px solid $secondary
                text-align: center
                font-family: "Poppin bold"
                font-size: 6vw
                svg
                    margin: 0 auto
                    width: 10vw
                    height: 10vw
                    margin-top: 20vw