#back
    position: absolute
    top: 0
    left: 2vw

#edit
    position: absolute
    top: 0
    right: 2vw

.edit
    display: flex
    position: absolute
    margin-left: 25vw
    margin-top: -1.5vw
    .btn-primary-icon
        min-width: 0vw !important
        padding: 0 1.8vw !important

.playerimg
    margin-top: 8vw
    margin-left: 5vw
    margin-bottom: 2vw
    width: 20vw
    height: 20vw
    
.playername
    position: absolute
    top: 5vw
    left: 40vw
    text-align: center

.playerdescription
    position: absolute
    top: 8vw
    left: 40vw
    text-align: center

.stats
    position: absolute
    top: 13vw
    left: 32vw

.stat
    display: flex
    flex-direction: row
    justify-content: space-between
    margin-bottom: 1vw
    border: .2vw solid #6697E6
    width: 20vw
    padding: 1vw 2vw

.money
    position: absolute
    top: 29vw
    left: 10vw

.moneyitem
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    img
        width: 3vw
        height: 3vw
        margin-right: 2vw
    
.skills
    margin: 7vw 5vw
    ul
        display: flex
        flex-wrap: wrap

        li
            margin-top: 2vw
            width: 13.39vw
            display: flex
            align-items: center
            padding: 0 2vw
            svg
                width: 1vw
                height: 1vw
                cursor: pointer
            &::before
               content: ""
               width: 0.5vw
               height: 0.5vw
               background: black
               border-radius: 100%
               margin-right: 0.4vw